.AppHeader {
  box-sizing: border-box;
  margin: 0;
  /* background-color: #051015; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;
  color: white;
  /* width: 30%; */
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 800px) {
  .AppHeader {
    /* flex-direction: row; */
    /* gap: 2rem; */
  }
}
