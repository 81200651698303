:root {
  --dark: #051015;
}

nav {
  background-color: #010a0e;
}

html {
  width: 100%;
}

body {
  background: #051015;
  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  font-size: calc(10px + 2vmin);
}

#root {
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
main {
  width: 100%;
  box-sizing: border-box;
}

body * {
  /* border: 1px solid #555; */
  /* border: 1px solid transparent; */
}

.App {
  box-sizing: content-box;
  margin: 0;
  padding: 0;
}

section {
  /* background-color: #11181c; */
  position: relative;
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  height: auto;
  text-align: left;
  color: #ededed;
  text-align: left;
}

p {
  font-size: 1rem;
}

h1,
h2,
h3 {
  text-shadow: 2px 2px 2px rgba(0.2, 0.2, 0.2, 0.4);
}

h1 {
  margin-top: 1rem;
  text-align: left;
  margin-bottom: 1rem;
  font-weight: 800;
  color: #e2a91a;
  color: white;
  font-family: 'DeadHead', cursive;
  /* font-family: 'Painter', cursive; */
  font-size: 3.5em;
  font-size: 8rem;
  letter-spacing: 0.1rem;
  /* font-kerning: 400px; */
}

h2 {
  margin-top: 5px;
  margin-bottom: 5px;
  /* padding-left: 1.25rem; */
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  text-align: left;
  font-size: 2rem;
  color: #e2a91a;
  color: white;
}

h3 {
  color: #bee500;
  color: #eee;
}
a {
  color: inherit;
}

.squareBrackets {
  background-image: linear-gradient(#e7b22c, #e7b22c),
    linear-gradient(#e7b22c, #e7b22c), linear-gradient(#e7b22c, #e7b22c),
    linear-gradient(#e7b22c, #e7b22c);

  background-repeat: no-repeat;
  background-size: 8px 3px;
  /* background-position: top right; */
  /* // ^^^ This value should be equal to width of left OR right border. */
  background-position: top left, top right, bottom left, bottom right;
  border: solid #e7b22c;
  border-width: 0px 3px;
}

@keyframes slidein {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0%;
  }
}

.ProjectItem > p {
  margin-left: 1.5em;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  section {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  section {
    padding-left: 10%;
    padding-right: 10%;
  }
}
