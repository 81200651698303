.writeMask {
  fill: none;
  stroke: #fff;
  stroke-width: 25px;
  /* stroke-dasharray: 800, 1250, 0, 0; */
  animation: sign 1s ease-in-out;
}

@keyframes sign {
  0% {
    stroke-dasharray: 0, 1250, 0, 0;
  }
  100% {
    stroke-dasharray: 800, 1250, 0, 0;
  }
}
