.Contact {
  /* font-family: "Merriweather", serif; */
  /* font-size: calc(10px + 2vmin); */
  text-align: left;
  color: rgb(157, 157, 157);
  /* width: fit-content; */
  /* position: relative; */
  /* top: 4rem; */
}

.Contact div {
  /* padding: 0.25rem; */
}

.Contact .icon {
  color: white;
  margin-right: 0.5rem;
  position: relative;
  top: 3px;
  /* padding-top: 0.15rem; */
}
