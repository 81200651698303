/* @import url("https://fonts.googleapis.com/css2?family=Alex+Brush&family=Molle&family=Pacifico&display=swap"); */
/* 
@font-face {
  font-family: "Molle", cursive;
  src: url("https://fonts.googleapis.com/css2?family=Molle");
} */
/* @import url('https://fonts.googleapis.com/css2?family=Molle&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Molle&display=swap'); */

@font-face {
  font-family: 'Molle';
  src: local('Molle'), url('./fonts/Molle-Italic.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Painter';
  src: local('Painter'), url('./fonts/The-Painter.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'DeadHead';
  src: local('DeadHead'),
    url('./fonts/Deadhead-Script-Italic.ttf') format('truetype');
  font-weight: bold;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
