/*Created by bash script*/
.projects {
  display: grid;
  box-sizing: border-box;
  gap: 10px;
  /* background-color: tomato; */
  /* padding: 10px 0; */
}

div.project {
  /* background-color: rgb(23, 36, 41); */
  padding: 1rem 1.25rem;
  max-height: 50vh;
  /* margin-bottom: 1rem; */
  border-radius: 0.25rem;
  /* box-shadow: 3px 5px 2px rgba(10, 10, 10, 0.5); */
  /* overflow-y: scroll; */
}

div.project img {
  width: 100%;
}

div.project h3 {
  margin-top: 0;
}

@media only screen and (min-width: 500px) {
}

@media only screen and (min-width: 600px) {
  .projects {
    grid-template-columns: 33% 33% 33%;
    gap: 10px;
  }
}

@media only screen and (min-width: 1600px) {
  .projects {
    grid-template-columns: 24% 24% 24% 24%;
    gap: 10px;
  }
}
