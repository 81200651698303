button {
  font-size: 0.65rem;
  background-color: #2d3238;
  color: #efefef;
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
}

button:active {
  background-color: tomato;
}

/* @media only screen and (min-width: 1450px) {
  .projects {
    grid-template-columns: 19% 19% 19% 19% 19%;
    gap: 1%;
  }
} */
